<template>
  <v-card class="mx-auto" elevation="5" width="100%" max-width="38rem" rounded="xl">
    <LanguageToolbar />

    <v-card width="100%" max-width="30rem" class="mx-auto" elevation="0">
      <v-card-title>
        <h1 class="font-weight-bold text-left mb-0">
          {{ $t('login.password_recovery_heading') }}
        </h1>
      </v-card-title>

      <v-card-text class="pb-10">
        <v-row align="center" justify="center" class="pb-4">
          <v-col cols="12">
            <p class="text-subtitle-1 text-left pa-0 mb-0">
              {{ $t('login.password_changed_text') }}
            </p>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" x-large block @click="$router.push({ name: 'login' })">
              {{ $t('general.continue') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import LanguageToolbar from '@access/components/LanguageToolbar';

export default {
  name: 'ResetForgottenPasswordDone',
  components: {
    LanguageToolbar,
  },
};
</script>

<style lang="scss" scoped></style>
